<section class="section about"
	id='prestar'>
	<div class="banner">
		<div *ngFor='let slide of "Lendview" |translate; let i = index'
			class="container d-flex">
			<div class="col-md-7 banner-section">
				<h2 [innerHTML]="slide.title | translate"></h2>
				<p [innerHTML]="slide.description | translate"
					class="mt-3"></p>
				<div class="section-calc">
					<label for="time">
						{{"Generico.Monto" | translate}}
						<div class="input-section mt-2">
							<input [(ngModel)]="monto"
								type="number"
								min="100"
								max="14000"
								placeholder="Invierte el monto que deseas"
								class="editable">
						</div>
					</label>
					<div *ngIf="montoError"
						class="text-red-500">
						<p class="alert-text">{{ montoError }}</p>
					</div>
					<label for="time">
						{{"Generico.Tiempo" | translate}}
					</label>
					<div class="flex items-center ">
						<input [(ngModel)]="plazo"
							[style.background]="sliderBackgroundPlazo"
							(input)="updateSliderColor();"
							id="range2"
							type="range"
							min="1"
							max="24"
							step="1"
							class="w-full h-2 appearance-none cursor-pointer">
					</div>
					{{ plazo }} meses
					<div *ngIf="plazoError"
						class="text-red-500">
						<p class="alert-text">{{ plazoError }}</p>
					</div>
					<button (click)="validateInputs()"
						class="invest-btn mt-4 mb-2">
						{{"AboutMe.Actionbtn" | translate}}
					</button>
				</div>
			</div>
			<div class="col-md-4 banner-left"></div>
		</div>
	</div>
	<div *ngIf="showContainerCalc"
		class="container-calc">
		<div class="container-description">
			<div class="card">
				<div class="installment">
					<p>Ganancia total</p>
					<h2>Bs. {{TotalInvest}}</h2>
				</div>
				<div class="loan-details">
					<div class="detail">
						<span>Monto de inversión</span>
						<span>Bs. {{monto}}</span>
					</div>
					<div class="detail">
						<span>Tasa de rendimiento</span>
						<span>
							{{percentageTotal}} %
						</span>
					</div>
					<div class="detail">
						<span>Perido de inversión</span>
						<span>{{plazo}} Meses</span>
					</div>
					<div class="detail">
						<span>Utilidad</span>
						<span>Bs. {{auxMonto}}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="empty-card"
			id="table-container">
			<app-my-chart [amount]="monto"
				[time]="plazo"
				[percentage]="percentageTotal"
				[total]="totalgain"
				[color]="'rgba(156, 155, 161, 1)'"
				[color2]="'rgba(18, 75, 96, 1)'"
				[tittle]="'Inversión vs Rendimiento'"
				[subTittle1]="'Inversión'"
				[subTittle2]="'Rendimiento'"
				[type]="'inversion'"
				class="mb-4"
				id="myChart"></app-my-chart>
		</div>
	</div>
	<app-sectionInvertir></app-sectionInvertir>
	<div class="requirements-layout">
		<span [innerHTML]='"prestarBeneficios.Title" | translate'
			class="requirements-title steps-title"></span>
		<h2 [innerHTML]='"prestarBeneficios.SubTitle" | translate'
			class="requirements-subtitle"></h2>
		<p [innerHTML]='"prestarBeneficios.Description" | translate'
			class="requirements-description mt-4"></p>
		<div class="container-section">
			<div class="requirements-image">
				<img src="assets/images/sectionprestar.png"
					alt="Persona en escritorio">
			</div>
			<div class="requirements-container">
				<div class="requirements">
					<div *ngFor="let item of 'requerements.text' | translate; let i = index"
						class="requirement">
						<div class="container-icon">
							<i class="material-icons">{{item.icon}}</i>
						</div>
						<h5>{{item.title}}</h5>
						<p>
							{{item.description}}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<app-sectionEmpresasAsociados></app-sectionEmpresasAsociados>
	<app-sectionComentario></app-sectionComentario>
	<app-sectionAprovecharLend></app-sectionAprovecharLend>
	<app-questions></app-questions>
	<app-buttonWhatsapp></app-buttonWhatsapp>
</section>
